<template>
  <UiButton
    v-bind="$attrs"
    data-el="m2-add-to-wishlist-btn"
    :variant="internalVariant === VariantEnum.ICON ? 'icon' : 'secondary'"
    :disabled="props.disabled"
    :icon-left="
      internalVariant === VariantEnum.DEFAULT
        ? {
          name: 'list',
          width: 16,
          height: 12,
        }
        : null
    "
    :class="[{ 'shrink-0': internalVariant === VariantEnum.ICON }]"
    :slim="internalVariant === VariantEnum.DEFAULT"
    :data-test="isItemWishlisted ? 'm2-remove-from-wishlist-button' : 'm2-add-to-wishlist-button'"
    @click="addToWishlist(props.product)"
  >
    <template v-if="internalVariant === VariantEnum.ICON">
      <UiIcon
        v-if="isItemWishlisted"
        name="remove-from-list"
        class="text-primary transition-colors"
        :class="{
          'group-hover:text-grey': props.disabled,
        }"
        :width="29"
        :height="23"
      />
      <UiIcon
        v-else
        name="add-to-list"
        class="text-primary transition-colors"
        :class="{
          'group-hover:text-grey': props.disabled,
        }"
        :width="29"
        :height="23"
      />
    </template>
    <template v-if="internalVariant === VariantEnum.DEFAULT && !$slots.default">
      <span>{{
        isItemWishlisted
          ? t('ecom_remove_from_list')
          : t('ecom_add_to_list')
      }}</span>
    </template>
  </UiButton>
</template>

<script setup lang="ts" generic="T extends ProductAddableToWishlist | null">
import type { ProductAddableToWishlist } from '@customer/types/wishlist'

const props = withDefaults(defineProps<M2AddToWishlistButton>(), {
  variant: 'default',
  disabled: undefined,
  isItemWishlisted: false,
})

const emit = defineEmits<{
  (e: 'click:add-to-wishlist', product: T): void
}>()

enum VariantEnum {
  ICON = 'icon',
  DEFAULT = 'default',
}

type VariantType = `${VariantEnum}`

interface M2AddToWishlistButton {
  variant?: VariantType
  disabled?: boolean
  isItemWishlisted?: boolean
  product: T
}

const { t } = useI18n()

function addToWishlist(product: T) {
  if (!product?.uid || !product?.sku || !product?.__typename) {
    return
  }
  emit('click:add-to-wishlist', product)
}

const internalVariant = computed(() => {
  if (props.variant !== VariantEnum.ICON) {
    return VariantEnum.DEFAULT
  }
  return props.variant
})
</script>
